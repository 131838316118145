<template>
    <div class="c-anonymous-home-row-one">
        <TwoUpRowModule
            v-if="twoUpRow"
            :content="twoUpRow"
            class="c-anonymous-home-row-one__two-up"
            @click="$emit('click', $event)"
        />
        <ContentScrollFadeIn v-slot="{ hasEnteredViewport }">
            <HomepageRecTray
                v-if="recTray"
                :id="id"
                :recommendationTrayComponent="recommendationTray"
                :recTray="recTray"
                :contentModuleId="recTrayContentModuleId"
                :contentTitle="contentTitle"
                :hasEnteredViewport="hasEnteredViewport"
            />
        </ContentScrollFadeIn>
    </div>
</template>

<script>
import { markRaw, defineAsyncComponent } from 'vue';

import { COMMON_HOME_MODULE_IDS } from '~routes/homepage/js/homepage-constants';

import TwoUpRowModule from '~coreModules/contentful/components/TwoUpRowModule.vue';
import ContentScrollFadeIn from '~coreModules/core/components/ui/ContentScrollFadeIn.vue';
import HomepageRecTray from '~coreRoutes/homepage/components/HomepageRecTray.vue';

export default {
    name: 'CommonAnonymousHomepageContentRow1',
    components: {
        TwoUpRowModule,
        ContentScrollFadeIn,
        HomepageRecTray,
    },
    props: {
        id: {
            type: String,
            default: null,
        },
        twoUpRow: {
            type: Object,
            default: null,
        },
        recTray: {
            type: Object,
            default: null,
        },
        contentTitle: {
            type: String,
            default: null,
        },
    },
    emits: ['click'],
    data() {
        return {
            recommendationTray: markRaw(
                defineAsyncComponent(
                    () => import(
                        /* webpackChunkName: "catalogTray" */
                        '~modules/core/components/ui/CatalogTray.vue'),
                ),
            ),
            recTrayContentModuleId: COMMON_HOME_MODULE_IDS.recTrayRow,
        };
    },
};
</script>

<style lang="scss">
.c-anonymous-home-row-one {
    background-color: $nu-secondary;
    padding: $nu-spacer-12 0;

    @include breakpoint(medium) {
        padding: $nu-spacer-16 0;
    }

    @include breakpoint(large) {
        padding: $nu-spacer-16 0;
    }

    &__two-up {
        padding-left: $nu-spacer-2;
        padding-right: $nu-spacer-2;

        @include breakpoint(medium) {
            padding-left: $nu-spacer-5;
            padding-right: $nu-spacer-5;
        }

        @include breakpoint(large) {
            padding-left: $nu-spacer-8;
            padding-right: $nu-spacer-8;
        }
    }
}
</style>
