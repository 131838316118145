<template>
    <div class="c-homepage-rec-tray">
        <div class="c-homepage-rec-tray__text">
            <h4 v-if="recTray.headline" class="u-text--center c-homepage-rec-tray__heading">
                {{ recTray.headline }}
            </h4>
        </div>
        <component
            :is="recommendationTrayComponent"
            :id="`anonymous-homepage-tray-${recTray.traySlug}`"
            :listId="id"
            :listName="recTray.traySlug"
            :categorySlug="recTray.traySlug"
            :shouldShowHeaderLink="true"
            :displayCounter="true"
            :lightButtons="true"
            :xlDesktopSlideSize="6"
            :maxProducts="recTray.maxProducts"
            :contentModuleId="contentModuleId"
            :contentTitle="contentTitle"
            :hasEnteredViewport="hasEnteredViewport"
            class="c-homepage-rec-tray__tray"
        />
    </div>
</template>

<script>
export default {
    name: 'HomepageRecTray',
    props: {
        recommendationTrayComponent: {
            type: [Function, Object],
            required: true,
        },
        id: {
            type: String,
            default: null,
        },
        recTray: {
            type: Object,
            required: true,
        },
        contentModuleId: {
            type: String,
            default: null,
        },
        contentTitle: {
            type: String,
            default: null,
        },
        hasEnteredViewport: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
    .c-homepage-rec-tray {
        &__text {
            text-align: center;
            padding: 0 $nu-spacer-2;
            margin: 0 $nu-spacer-2;

            @include breakpoint(small) {
                padding: 0;
                margin: 0 $nu-spacer-4;
            }

            @include breakpoint(large) {
                margin: 0 $nu-spacer-8;
            }
        }
    }
</style>
